import React from "react";
import { NavLink, Link } from "react-router-dom";
import { BiLogoLinkedin, BiLogoFacebook, BiLogoInstagramAlt, BiLogoYoutube, BiHeart } from "react-icons/bi";
import FooterLogo from '../../assets/images/logo.png'
import FooterEsightLogo from '../../assets/images/esight.png'

const path = "";

function Footer() {

    return (
        <footer>
            <div className="container">
                <div className="footerMain">
                    <div className="footerCol1">
                        <div className="footerLogo">
                            <img src={FooterLogo} alt="" />
                        </div>
                        <div className="footerSocialLinks">
                            <Link to="https://www.linkedin.com/company/daniels-job-finder" target="_blank"><BiLogoLinkedin /></Link>
                            <Link to="https://www.facebook.com/danielsjobfinder" target="_blank"><BiLogoFacebook /></Link>
                            <Link to="https://www.instagram.com/daniels_job_finder?igsh=MWhxcm52Zjl3MmV6dw==" target="_blank"><BiLogoInstagramAlt /></Link>
                            {/* <Link to="" target="_blank"><BiLogoYoutube /></Link> */}
                        </div>
                    </div>
                    <div className="footerCol2">
                        <div className="footerBox1">
                            <h2>Quick Links</h2>
                            <ul>
                                <li>
                                    <NavLink to={`${path}/`} onClick={() => window.scrollTo(0, 0)}>Home</NavLink>
                                </li>
                                <li>
                                    <NavLink to={`${path}/jobs`} onClick={() => window.scrollTo(0, 0)}>Jobs</NavLink>
                                </li>
                                <li>
                                    <NavLink to={`${path}/immigration`} onClick={() => window.scrollTo(0, 0)}>Immigration</NavLink>
                                </li>
                                <li>
                                    <NavLink to={`${path}/service`} onClick={() => window.scrollTo(0, 0)}>Services</NavLink>
                                </li>
                            </ul>
                        </div>
                        <div className="footerBox3">
                            <h2>Company</h2>
                            <ul>
                                <li>
                                    <NavLink to={`${path}/about`} onClick={() => window.scrollTo(0, 0)}>About</NavLink>
                                </li>
                                <li>
                                    <NavLink to={`${path}/blog`} onClick={() => window.scrollTo(0, 0)}>Blogs</NavLink>
                                </li>
                                <li>
                                    <NavLink to={`${path}/testimonials`} onClick={() => window.scrollTo(0, 0)}>Testimonials</NavLink>
                                </li>
                                <li>
                                    <NavLink to={`${path}/gallery`} onClick={() => window.scrollTo(0, 0)}>Gallery</NavLink>
                                </li>
                            </ul>
                        </div>
                        <div className="footerBox2">
                            <h2>Support</h2>
                            <ul>
                                <li>
                                    <NavLink to={`${path}/faq`} onClick={() => window.scrollTo(0, 0)}>FAQ</NavLink>
                                </li>
                                <li>
                                    <NavLink to={`${path}/privacyPollicy`} onClick={() => window.scrollTo(0, 0)}>Privacy and Pollicy</NavLink>
                                </li>
                                <li>
                                    <NavLink to={`${path}/termsConditions`} onClick={() => window.scrollTo(0, 0)}>Terms and Conditions</NavLink>
                                </li>
                                <li>
                                    <NavLink to={`${path}/CancellationAndRefund`} onClick={() => window.scrollTo(0, 0)}>Cancellation and Refund</NavLink>
                                </li>
                                <li>
                                    <NavLink to={`${path}/ShippingAndDelivery`} onClick={() => window.scrollTo(0, 0)}>ShippingAndDelivery</NavLink>
                                </li>
                                <li>
                                    <NavLink to={`${path}/contact`} onClick={() => window.scrollTo(0, 0)}>Contact</NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="footerStrip">
                    <div className="footerStripCol1">
                        <p>Copyright © 2024 Daniels International Recruitment Pvt Ltd.</p>
                    </div>
                    {/* <div className="footerStripCol2">
                        <p>Designed with</p>
                        <BiHeart />
                        <Link to="https://esightsolutions.com/" target="_blank" className="footerStripLogo">
                            <img src={FooterEsightLogo} alt="Esight Logo" />
                        </Link>
                    </div> */}
                    <div className="footerStripCol1">
                        <p>All Rights Reserved</p>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
